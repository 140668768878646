@import "../../../styles/flex";

.tableCell {
    .roundImageContainer {
        @include flex(column, center, center);
        width: 3.5rem;
        height: 3.5rem;
        padding: 10px;
        margin: auto;

        .roundImage {
            overflow: hidden;
            object-fit: cover;
            border-radius: 50%;
            height: 100%;
            width: 100%;
        }
    }
    .booleanSuccess {
        font-weight: 700;
        background-color: #59ff72;
        box-sizing: border-box;
        color: black;
        
    }
    .booleanFail {
        font-weight: 700;
        background-color: rgb(245, 17, 28);
        border-radius: 4px;
        color: white;
    }
}
