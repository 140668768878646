@import "../../../../styles/dimentions";
@import "../../../../styles/flex";
@import "../../../../styles/colors";
@import "../../../../styles/card";

.salesContent {
    @include flex(column, flex-start, flex-start);
    width: 100%;
    .salesBlock {
        width: 100%;
    }

    .salesDetailLine {
        @include flex(row, flex-start, flex-start);
        width: 100%;

        @media (max-width: $mobile-width) {
            @include flex(row, center, center);
            margin: 0;

            flex-wrap: wrap;
            .componentButton {
                @include flex(column, space-around, flex-end);

                margin: 10px;

                .descriptionDate {
                    font-size: 14px;
                }
            }
        }
    }

    .salesDetail {
        @include flex(row, flex-start, center);
        width: 45%;
        @media (max-width: $tablet-width) {
            @include flex(column, flex-start, flex-start);
            width: 45%;
            margin-bottom: 10px;
        }

        label {
            margin-right: 10px;
            color: $primary-color;

            @media (max-width: $tablet-width) {
                font-size: 12px;
                margin: 0;
            }
        }

        p {
            margin: 0;
        }
    }
}
