@import "../../../../styles/flex";
@import "../../../../styles/card";
@import "../../../../styles/colors";
@import "../../../../styles/dimentions";

.userContainer {
	@include flex(column, flex-start, flex-start);
	height: 100%;
	width: 100%;

	&Wrapper {
		@include flex(column, flex-start, flex-start);
		@extend %card;

		width: 55%;
		box-sizing: border-box;
		padding: 16px 30px;
		margin: 20px auto;
		color: $black;

		@media (max-width: $mobile-width) {
			@include flex(column, center, center);
			width: 100%;
			margin: 0;
		}

		@media (max-width: $tablet-width) {
			width: 100%;
			margin: 0;
		}

		.creditsTable {
			width: 100%;
			max-height: calc(80vh - (80px - 20px));
		}

		.creditUsersTable {
			width: 100%;
			max-height: calc(80vh - (80px - 20px));
		}

		.buttonContainer {
			@include flex(row, space-between, center);
			padding-top: 10px;
			width: 100%;

			@media (max-width: $mobile-width) {
				flex-wrap: wrap;

				.backButton {
					width: 100%;
					margin-bottom: 10px;
				}

				.createButton {
					width: 100%;
				}
			}
		}
	}
}
