@import "../../styles/colors";

.normalButton {
	font-size: 18px;
	font-weight: bold;
	text-align: center;
	border: none;
	box-sizing: border-box;
	padding: 10px 20px;
	border-radius: 4px;
	color: $white;
	cursor: pointer;
	outline: none;
	background-color: $secondary-color;
	&[disabled] {
		cursor: not-allowed;
		background-color: $light-gray;
		color: $gray;
	}
}
