@import "../../../../styles/flex";
@import "../../../../styles/card";
@import "../../../../styles/colors";
@import "../../../../styles/dimentions";

.userCreateOrEdit {
	@include flex(column, flex-start, flex-start);
	height: 100%;
	width: 100%;

	&Wrapper {
		@include flex(column, flex-start, flex-start);
		@extend %card;

		width: 50%;
		box-sizing: border-box;
		padding: 16px 30px;
		margin: 20px auto;
		color: $primary-color;

		@media (max-width: $mobile-width) {
			width: 100%;
			margin: 0;
		}

		.userFormContainer {
			width: 100%;
			max-width: 900px;

			h2 {
				font-size: 18px;
			}

			.twoFieldsLabel {
				@include flex(row, flex-start, flex-start);
				width: 85%;

				.textFieldLabel {
					padding-right: 20px;
				}
			}
		}

		.buttons {
			@include flex(row, space-between, center);

			width: 100%;
			margin: 40px 0 10px;
		}

		.buttonsContainer {
			@include flex(row, flex-end, center);

			margin-top: 30px;
			width: 100%;

		}
	}

}
