@import "../../../styles/flex";

.adminUsers {
	@include flex(column, flex-start, flex-start);
	width: 100%;
	padding: 20px;

	.avaliationsIsEmpty {
		@include flex(row, center, center);

		width: 100%;
		height: 60px;
		font-size: 20px;
		font-style: italic;
	}
}
