@import "../../../styles/colors";
@import "../../../styles/flex";
@import "../../../styles/dimentions";

.toolbar {
	@include flex(row, start, center);

	background-color: $white;
	width: 100%;
	box-shadow: 0px 0.4px 2px rgba(0,0,0,0.1);
	position: fixed;
	top: 0px;
	padding: 0 !important;

	.menuIcon {
		color: $primary-color;
		padding-left: 10px;
		position: absolute;
		* {
			color: $primary-color;
		}
	}

	.title {
		color: black;
		padding-left: 12px;
	}
}

.subscriptionWarning {
	position: fixed;
	top: 60px;
	width: 100%;
	padding: 12px;
	text-align: right;
	z-index: 10;

	@media (max-width: $mobile-width) {
		text-align: left;
	}

	p {
		margin: 0;
		color: $white;
		font-size: 12px;
	}
}

.languageSelector {
	right: 0;
	top: 0;
	position: fixed;
	margin-top: 70px;
	width: 20%;
	padding: 0 30px;
}

.navbarPlaceholder {
	margin-top: 65px;
	visibility: hidden;
}

.icon {
		color: $primary-color;
 }

 .logoMenu {
	@include flex(row, center, center);

	img {
		width: 290px;
		height: 150px;
	}
}

.iconText {
		color: $primary-color;
		font-weight: bold;
}

.logoView {
	@include flex(row, center, center);

	width: 100%;
	cursor: pointer;

	img {
		width: 100px;
		height: 50px;
	}
}
