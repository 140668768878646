@import "../../styles/flex";
@import "../../styles/colors";
@import "../../styles/dimentions";

.docDetail {
	@include flex(row, space-between, center);
	width: 100%;
	background-color: $light-gray;
	padding: 15px;
	margin-bottom: 15px;
	border-radius: 15px;

	@media (max-width: $mobile-width) {
		@include flex(column, flex-start, flex-start);

		flex-wrap: wrap;
	}

	.title {
		margin: 0px;
		margin-right: 4px ;

		@media (max-width: $mobile-width) {
			margin-bottom: 5px ;	
		}
	}
	a {
		@include flex(row, center, center);
		font-weight: 200;
		text-decoration: none;
		margin: 0px;
	}
}
