@import "./styles/colors";

* {
	font-family: 'Muli', sans-serif;
	box-sizing: border-box;
}

#app, body, html {
	margin: 0;
	padding: 0;
	background-color: $background-color;
	height: 100%;
}
