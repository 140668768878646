@import "../../styles/colors";
@import "../../styles/flex";
@import "../../styles/card";

.emailRecovery {
	@include flex(column, center, center);
	width: 100%;
	height: 100vh;

	img {
		max-width: 300px;
	}

	&Card {
		@extend %card;
		width: 250px;

		.loginTitle {
			margin : 40px 0;
			font-size: 34px;
			font-weight: 600;
			text-transform: uppercase;
		}

		.textFieldContainer {
			width: 100%;
		}
	}

	.emailRecoveryButton {
		margin: 10px;
	}
}
