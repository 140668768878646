@import "../../styles/flex";

.userDataContainer {
	@include flex(row, space-between, flex-start);

	box-sizing: border-box;
	width: 100%;
}

.userContainerData {
	@include flex(column, center, flex-start);

	width: 100%;

}
