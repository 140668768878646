@import "../../styles/flex";
@import "../../styles/colors";
@import "../../styles/dimentions";

.itemDetail {
	@include flex(row, space-between, center);
	width: 100%;
	background-color: $light-gray;
	padding: 15px;
	margin-bottom: 15px;
	border-radius: 15px;

	@media (max-width: $mobile-width) {
		@include flex(column, flex-start, flex-start);

		flex-wrap: wrap;
	}

	.title {
		margin: 0px;
		margin-right: 4px ;

		@media (max-width: $mobile-width) {
			margin-bottom: 5px ;	
		}
	}
	.info {
		font-weight: 200;
		margin: 0px;
	}
}

.itemDetailDescription {
	@include flex(column, flex-start, flex-start);
	width: 100%;
	background-color: $light-gray;
	padding: 15px;
	margin-bottom: 15px;
	border-radius: 15px;
	.title {
		margin: 0px;
		margin-bottom: 5px;
	}
	.info {
		width: 100%;
		font-weight: 200;
		margin: 0px;
	}
}
