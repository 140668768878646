@import "../../../../styles/flex";
@import "../../../../styles/card";
@import "../../../../styles/colors";
@import "../../../../styles/dimentions";

.creditLogContainer {
	@include flex(column, flex-start, flex-start);
	height: 100%;
	width: 100%;

	&Wrapper {
		@include flex(column, flex-start, flex-start);
		@extend %card;

		width: 55%;
		box-sizing: border-box;
		padding: 16px 30px;
		margin: 20px auto;
		color: $black;

		@media (max-width: $tablet-width) {
			width: 100%;
			margin: 0;
		}
	}
}
