@import "../../../../styles/flex";
@import "../../../../styles/card";
@import "../../../../styles/colors";
@import "../../../../styles/dimentions";

.adminUserCreateOrEdit {
	@include flex(column, flex-start, flex-start);
	height: 100%;
	width: 100%;

	&Wrapper {
		@include flex(column, flex-start, flex-start);
		@extend %card;

		width: 50%;
		box-sizing: border-box;
		padding: 16px 30px;
		margin: 20px auto;
		color: $primary-color;

		@media (max-width: $mobile-width) {
			width: 100%;
			margin: 0;
		}

		@media (max-width: $tablet-width) {
			width: 100%;
			margin: 0;
		}

		.adminUserCreateOrEditHeader {
			width: 100%;
			@include flex(row, space-between,flex-start);

			.adminUserCreateOrEditTitle {
				width: 100%;
				text-align: left;
				font-weight: bolder;
				color: #0D2A5D;
			}
		}

		.adminUserFormContainer {
			width: 100%;
			max-width: 900px;

			h2 {
				font-size: 18px;
			}

			.textFielContainer {
				max-width: unset;
			}
		}

		.buttons {
			@include flex(row, space-between, center);

			width: 100%;
			margin: 40px 0 10px;
		}

		.buttonsContainer {
			@include flex(row, flex-end, center);

			margin-top: 30px;
			width: 100%;

		}
	}

}
