@import "../../../../styles/flex";
@import "../../../../styles/card";
@import "../../../../styles/colors";
@import "../../../../styles/dimentions";

.articleContainer {

	@include flex(column, flex-start, flex-start);
	@extend %card;

	width: 50%;
	box-sizing: border-box;
	padding: 16px 30px;
	margin: 20px auto;
	color: $black;

	@media (max-width: $tablet-width) {
		width: 100%;
		margin: 0;
	}

	@media (max-width: $mobile-width) {
		@include flex(column, center, center);
	}

	.imageContainer {
		width: 100%;

		img {
			width: 100%;
			height: 500px;
			object-fit: scale-down;
			background-color: $white;

			@media (max-width: $mobile-width) {
				height: 200px;
			};
		}
	}

	.textFieldContainer {
		max-width: unset;
		width: 100%;

		div {
			div {
				padding: 0;
				font-size: 18px;
				color: $black;
			}
		}
	}

	fieldset {
		border: none;
	}
}
