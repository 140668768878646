@import "../../styles/dimentions";
@import "../../styles/flex";
@import "../../styles/card";


.creditDataContainer {
	@include flex(row, space-between, flex-start);

	box-sizing: border-box;
	width: 100%;
}

.creditContainerData {
	@include flex(column, center, flex-start);

	width: 100%;

}
