@import "../../../../styles/flex";
@import "../../../../styles/colors";
@import "../../../../styles/dimentions";

.adminUsersTableContainer {
	@include flex(column, center, center);
    width: 100%;
	box-sizing: border-box;
	
	.adminUsersTitle {
		font-size: 28px;
		font-weight: bold;
		box-sizing: border-box;
		padding-top: 10px;
		color: $black;
	}

	.adminUsersTable {
		min-width: 800px;
		width: fit-content;
		box-sizing: border-box;

		@media (max-width: $tablet-width) {
			min-width: unset;
			width: 100%;
		}
		
		.tableFooter {
			@include flex(row, flex-end, flex-end);
	
			width: 100%;
	
			tfoot {
				@include flex(row, flex-end, flex-end);
	
				width: 100%;
			}
		}
	}
	
}
