// MARK: General
$white: #FFFFFF;
$light-gray: rgba(245, 245, 245, 1);
$gray: #818181;
$black: #000000;

// MARK: Logo
$primary-color: #1D4BA8;
$secondary-color: rgb(89, 89, 89);
$tertiary-color: rgb(21, 21, 21);

// MARK: Components
// Text
$dark-text-color: $black;
$text-color: $gray;

// Background
$background-color: rgb(235, 235, 235);

// Error
$error-color: #c0392b;

// Shadow
$shadow-color: rgba(0, 0, 0, 0.16);

// Separator
$separator-color: rgba(0, 0, 0, 0.10);
