@import "../../../../../styles/flex";
@import "../../../../../styles/card";
@import "../../../../../styles/colors";
@import "../../../../../styles/dimentions";

.adminUserPickerWrapper {
	@extend %card;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-height: 80vh;

	.serachWrapper {
		@include flex(row, space-between, center);

		.serachTextField {
			padding-right: 20px;
		}
	}

	.adminUsersWrapper {
		padding-top: 20px;
		padding-bottom: 20px;
		width: 100%;

		.adminUserRow {
			@include flex(row, space-between, center);

			p {
				margin-right: 20px;
			}
		}

		.paginationWrapper {
			@include flex(row, space-between, center);
			padding-top: 10px;
		}
	}
}

.selectedUserWrapper {
	padding-top: 10px;

	h3 {
		color: $dark-text-color;
		margin: 0;
		padding: 0;
	}

	.selectedUserContent {
		@include flex(row, flex-start, center);

		padding-top: 10px;

		p {
			color: $dark-text-color;
			margin-right: 20px;
		}
	}
}
