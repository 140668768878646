@import "../../../../styles/colors";
@import "../../../../styles/flex";
@import "../../../../styles/dimentions";

.navbarSearch {
    @include flex(row, center, center);
    position: relative;
    width: 100%;
    height: 40px;
    background: $white 0% 0% no-repeat padding-box;
    border-radius: 24px;
    opacity: 1;
    margin: 1% 0 1% 0;


    @media (max-width: $tablet-width){
        width: 100%;
    }

    .navbarInput {
        width: 100%;
        .textFieldContainer {
            width: 100%;

            *, *:after, *:before {
                border: 0 !important;
            }

            & > div {
                margin: 0;
            }
        }
    }

    .navbarInput input {
        width: 100%;
        color: $black;
        border: none;
        margin-left: 10px;
        padding: 10px;
        outline: 0;
        font-size: 12px;
    }

    .searchFeature {
        position: absolute;
        top: 100%;
        width: 90%;
        margin: 0;
        padding: 8px;
        padding-top: 0px;
        background: $white 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;

        @media (max-width: $tablet-width){
            width: 90%;
            top: 40px;
        }
    }

    .searchAutocomplete {
        @extend .searchFeature;

        padding-top: 0;
        z-index: 10;
        p {
            font-size: large;
            cursor: pointer;
            margin-right: 0;
        }
    }
}

.navbarIcon {
    margin-right: 10px;
    padding: 10px;
    cursor: pointer;


    img {
        height: 25px;
    }
}
