@import "../../../../styles/dimentions";
@import "../../../../styles/flex";
@import "../../../../styles/colors";
@import "../../../../styles/card";

.filterEnumContainer {
    width: 100%;
    .enumField {
        @include flex(column, flex-start, flex-start);
        width: 100%;
        padding: 10px 0;

        .enum {
            cursor: pointer;
            margin: 5px 0;
        }
        .selectedEnum {
            @extend .enum;
           color: $primary-color;
           font-weight: bold;
        }
    }
}
