@import "../../styles/dimentions";
@import "../../styles/flex";
@import "../../styles/colors";

.pageTableContainer {
    @include flex(row, flex-start, flex-start);
    box-sizing: border-box;
    width: 100%;

    .pageTableFilter {
        @include flex(column, flex-start, flex-start);
        box-sizing: border-box;
        position: absolute;
        height: 100%;
        padding: 32px 10px 20px;
        width: 17%;
        top: 50px;
        left: 0;
        z-index: 999;
        transition: width 0.3s, left 0.3s;
        overflow-y: auto;

        @media (max-width: $tablet-width) {
            @include flex(column, center, center);
            width: 100%;
        }

        .pageFilterContainer {
            position: relative;
            width: 100%;
            height: 100%;
        }

        &Closed {
            @extend .pageTableFilter;

            transition: left 0.3s;
            left: -100%;

            @media (max-width: $mobile-width) {
                left: -100%;
            }
        }

        h1 {
            margin: 30px 0px 15px 0px;
            align-self: center;
            color: $primary-color;
        }
    }

    .pageTable {
        box-sizing: border-box;
        width: 100%;
        height: calc(100vh - 60px);
        transition: padding-left 0.3s;
        align-items: flex-start;

        .statsContainer {
            margin-bottom: 15px;
        }

        .badgesView {
            @include flex(row, flex-start, flex-start);
            width: 94%;
            flex-wrap: wrap;
            height: auto !important;
            .metaTagChip {
                margin: 0px;
            }
        }
        .salesContent {
            height: auto !important;
        }
        .editTableView {
            @include flex(row, center, center);
            width: 100%;
            padding: 0 20px 20px 20px;
        }
        .table {
            width: 100%;
            height: 75%;

            .metaTagChip {
                margin: 5px;
            }
            & > div {
                height: 100%;
                .overflowX {
                    height: calc(100% - 120px);
                }
            }
            .toolbar {
                @include flex(row, flex-start, flex-start);
                width: 100%;
                &Icon {
                    margin: 2px 5px 2px 5px;
                    cursor: pointer;
                    transition: padding 0.3s, background-color 0.3s;
                    border-radius: 50%;

                    &Selected {
                        @extend .toolbarIcon;
                        background-color: $light-gray;
                        padding: 2px;
                        transition: padding 0.3s, background-color 0.3s;
                    }
                }
            }
            .searchInput {
                height: auto;
                h3 {
                    margin: 10 0 0 0;
                }
            }
        }

        &OnFilter {
            @extend .pageTable;
            margin-left: 16%;
            transition: padding-left 0.3s;
            width: 85%;

            @media (max-width: $tablet-width) {
                display: none;
            }

            .table {
                z-index: -999;
                width: 95%;
            }
        }
    }
    .downloadIcon {
        * {
            color: $white;
        }
    }
}

.onClear {
    background-color: transparent;
    display: inline;
    color: $primary-color;
    font-size: 14px;
    margin: 0 auto;
    h3 {
        margin: 0;
    }
}

.fieldButton {
    @include flex(row, center, center);
    width: 100%;
    margin-bottom: 20px;

    .onClose {
        @extend .onClear;
        display: none;
        font-size: 14px;

        @media (max-width: $tablet-width) {
            display: inline;
        }
    }
}

.filterSection {
    margin-bottom: 10px;
    width: 100%;

    h4 {
        margin: 0 -5px;
        color: $primary-color;
    }

    @media (max-width: $tablet-width) {
        @include flex(column, center, center);
        width: 100%;
    }
    .filterForm {
        @include flex(column, flex-start, center);
        width: 100%;

        .datePicker {
            input {
                padding: 15px 0 15px 5px;
            }
        }
    }
}

.submitFilterButtonDesktop {
    width: 100%;
    position: sticky;
    top: 5px;
    z-index: 50;

    @media (max-width: $tablet-width) {
        display: none;
    }
}

.submitFilterButtonMobile {
    @extend .submitFilterButtonDesktop;
    display: none;
    @media (max-width: $tablet-width) {
        display: inline;
    }
}

.onClear {
    width: 100%;
    h3 {
        text-align: center;
        text-decoration: none;
        color: $primary-color;
        cursor: pointer;
    }
}
