@import "../../../../styles/dimentions";
@import "../../../../styles/flex";
@import "../../../../styles/colors";
@import "../../../../styles/card";

.containerFilterText{
    @include flex(row, flex-start, center);
    width: 100%;
    padding: 10px 0;
    @media (max-width: $tablet-width) {
        @include flex(row, center, center);
        width: 100%;
    }
    .filterTextRow {
        @include flex(row, center, center);
        border-radius: 5px;
        border-width: 0.5px;
        border-style: solid;
        border-color: $primary-color;
        .periodTextSelect {
            font-size: smaller;
            text-transform: none;
            font-weight: bolder;
            color: $primary-color;
            cursor: pointer;
            padding: 0px 5px;   
        }
        .periodTextClear {
            font-weight: bold;
            color: $error-color;
            cursor: pointer;
        }
        button {
            padding: 0px;
            margin: 0px;
        }
    }
}
.popoverComponent {
    left: 30px !important;
}
.popoverFilterTextContainer {
    @include flex(column, flex-start, flex-start);
    padding: 20px;
    min-width: 200px;
    min-height: 180px;

    .popoverButton {
        width: 100%;
         margin-top: 10px;
        .periodButtonConfirm {
            width: 100%;
            background-color: $primary-color;
            .MuiButton-label {
                color: $white;
                font-weight: bold;
            }
        }
    }


}
