@import "../../styles/colors";
@import "../../styles/dimentions";

.mainLayoutContainer {
	background-color: $background-color;

	width: 100%;
	height: 100vh;
	padding: 0;

	margin: 0;

	.mainLayoutChildrenContainer {
		padding-top: $navbar-height;
		min-height: 80%;
	}
}
