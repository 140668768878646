@import "../../styles/card";
@import "../../styles/flex";

.imageCase {
    @include flex(row, start, center);
    flex-wrap: wrap;
    width: 100%;
    max-width: 870px;
    border-radius: 6px;
    margin-top: 10px;
    border: dashed 1px gray;
    box-sizing: border-box;
    padding: 14px;

    h4 {
        width: 100%;
        text-align: center;
    }
}

.imageCaseHeader {
    @include flex(row, start, center);
    margin-top: 18px;
    .imageCaseHeaderButton {
        margin-left: 20px;
        min-width: 130px    ;
    }
    h2 {
        margin: 0 16px 0 0;
        color: #000;
    }
}
