@import "../../../../styles/dimentions";
@import "../../../../styles/flex";
@import "../../../../styles/colors";
@import "../../../../styles/card";

.filterContainer {
    width: 100%;
    .containerField {
        @include flex(column, flex-start, flex-start);
        width: 100%;
        padding: 10px 0;

        @media (max-width: $tablet-width) {
            @include flex(row, center, center);
            width: 100%;
        }

        .field {
            cursor: pointer;
            margin: 5px 5px;
            border-radius: 20px;
            border-width: 0.5px;
            border-style: solid;
            padding: 4px 7px;
            font-weight: bolder;
            font-size: 12px;
            color: $primary-color;
            border-color: $primary-color;
        }
        .fieldActive {
            @extend .field;
            color: $white;
            background-color: $primary-color;
        }
    }
}
