@import "../../../../styles/flex";
@import "../../../../styles/colors";
@import "../../../../styles/dimentions";

.articlesTableContainer {
	@include flex(column, center, center);
    width: 100%;

	.articlesTable {
		width: 95%;
		@media (max-width: $tablet-width) {
			width: 100%;
		}
	}
}
