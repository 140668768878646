@import "../../styles/colors";
@import "../../styles/dimentions";
@import "../../styles/flex";

.componentCard {
	@include flex(column, space-around, flex-end);

	box-sizing: border-box;
	height: 120px;
	padding: 1em;

	.title {
		width: 100%;
		
		h2 {
			margin: 0px;
			text-align: left;
		}	
	}

	.infoGroup {
		@include flex(row, flex-start, center);
		width: 100%;

		.descriptionDate {
			@include flex(row, flex-start, center);

			width: 200px;
			margin-right: 8px;
			padding: 10px;
			color: $black;
			font-size: 13px;
			background-color: #E2E2E2;
			border-radius: 10px;
	}

		.descriptionLabel {
			@include flex(row, flex-end, center);

			padding: 0;
			font-size: 60px;
			color: $primary-color;
			font-weight: bold;
		}
	}

	.componentButtonFooter {
		@include flex(row, space-between, center);
		width: 100%;
	}

	.componentButtonFooterData {
		margin: 0;
		padding: 0;
		font-size: 14px;
		color: $gray;
	}
	.percentage {
		@include flex(row, center, center);
		padding: 0;

		align-self: flex-end;
		font-size: 14px;
		color: $gray;

		.loading {
			@include flex(row, center, center);

			width: 15px;
			height: 15px;
		}
		.arrowIcon {
			padding: 0;
			margin: 0;
			width: 15px;
			height: 10px;
			fill: $gray;
		}
	}
}
