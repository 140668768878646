@import "../../styles/colors";
@import "../../styles/flex";
@import "../../styles/card";
@import "../../styles/dimentions";

.login {
	@include flex(column, center, center);
	width: 100%;
	height: 90vh;
	color: $tertiary-color;

	@media (max-width: $mobile-width) {
		height: 37em;
	}

	img {
		max-width: 300px;
	}

	&Card {
		@extend %card;
		min-width: 20%;

		@media (max-width: $mobile-width) {
			width: 70%;
		}

		.loginTitle {
			margin : 40px 0;
			font-size: 34px;
			font-weight: 600px;
		}
	}

	.loginButton {
		margin: 10px;
		padding-top: 5px;
		background-color: $tertiary-color;
		border-color: $tertiary-color;
		color: $white;
		font-weight: bold;
		width: 90px;
		height: 45px;

	}

	.groupButton {
		@include flex(column, center, center);
		padding-top: 4px;
	}
}



